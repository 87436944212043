Vue.component('orderDetailComponent', {
    data:function () {
        return {
            backup:null,
            refreshFlag:1,
            showDetail:false,
            detailItem:null
        };
    },
    computed: {
        viewMode:function (){
            if(this.$store.getters.getCarStatus != 'view')
                return false;
            return true;
        },
        currentOrder: function (){
            return this.$store.getters.getCurrentOrder;
        },
        editable:function () {
            let editableOrder = this.$store.getters.getOrderEditableStates.indexOf(this.currentOrder.fields.OrderStatus) != -1;
            if(!editableOrder)
                return false;
            if(editableOrder && (this.$store.getters.getUserOnlyModifier || this.$store.getters.getUserIsAdmin || this.liberator))  //|| this.currentOrder.fields.Contact == this.$store.getters.getSession.fields.Code
                return true;
            return false;
        },
        canByCopy:function (){
            if(this.$store.getters.getUserOnlyModifier)
                return false;
            return true;
        },
        liberator:function () {
            let mustLibarate = false;
            if(this.$store.getters.getUserOnlyModifier || this.currentOrder.fields.OrderStatus != 'PRE')
                return false;
            if(this.$store.getters.orderCanLiberate)
                mustLibarate = true;
            return mustLibarate;
        },
        copier:function (){
            if(this.$store.getters.getUserOnlyModifier)
                return false;

            for(let item of this.currentOrder.fields.Items){
                if(!this.$store.getters.getItemByCode(item.fields.ArtCode))
                    return false;
            }
            return true;
        },
        buttonStyle: function (){
            return {"disabled":this.$store.getters.getCarStatus == "loading"};
        },
        buttonStyleViewMode: function (){
            return {"disabled":this.$store.getters.getCarStatus != "view"};
        }
    },
    /*mounted:function(){
      if(!store.getters.getSession)
          this.$router.push({"name":"HomeLink"})
    },*/
    updated:function (){
        if(this.$route.query.action && this.editable)
            this.$store.dispatch('changeCarStatus',this.$route.query.action);

    },
    methods:{
        goBack:function (){
            this.$store.commit('updateCurrentOrder', null);
            this.$router.go(-1);
        },
        itemQtyChange(itemCode){
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'update',itemQty:itemCode.cant});
            //this.$store.commit('updateCarAmount');
        },
        itemQtyIncrement:function (orderItem){
            orderItem.fields.Qty  = parseInt(orderItem.fields.Qty) + 1;
            this.updateSubTotals();
        },
        itemQtyDecrement:function (orderItem){
            if(parseInt(orderItem.fields.Qty) > 0)
                orderItem.fields.Qty = parseInt(orderItem.fields.Qty) - 1;
            this.updateSubTotals();
        },
        deleteItemInOrder:function (orderItem) {
            this.$store.dispatch('deleteItemCurrenOrder',orderItem);
        },
        copyOrder: function () {
            this.$store.dispatch('copyOrder',{orderId:this.currentOrder.fields.SerNr});
        },
        updateSubTotals:function (){
            let totalWithIVA = 0;
            let totalWithOutIVA = 0;
            for(let item of this.currentOrder.fields.Items){
                item.fields.RowNet = item.fields.Price * item.fields.Qty;
                item.fields.RowTotal = item.fields.VATPrice * item.fields.Qty;
                totalWithIVA += item.fields.RowTotal;
                totalWithOutIVA += item.fields.RowNet;
            }
            this.currentOrder.fields.SubTotal = totalWithOutIVA;
            this.currentOrder.fields.Total = totalWithIVA;
        },
        changeMode(mode = ""){
            this.$store.dispatch('changeCarStatus', mode);
        },
        async saveOrder(){
            let self = this;
            this.changeMode("loading");
            await this.$store.dispatch('saveCurrentOrder').then((actionCommit)=>{
                self.changeMode('view');
            });

        },
        cancelEdit(){
            this.changeMode("loading");
            this.$store.commit('updateCurrentOrder', this.backup);
            this.refreshFlag = this.refreshFlag + 1;
            this.changeMode('view');
        },
        orderAction(action) {
            this.$store.dispatch('orderAction',{action:action,sernr:this.encode(this.currentOrder.fields.SerNr),date:this.currentOrder.fields.TransDate,decodeSerNr:this.currentOrder.fields.SerNr}).then((actionCommit)=>{
                // eslint-disable-next-line
                console.log("Result Order Action",actionCommit);
            });
        },
        getVATCode(item){
            let VATCode = this.$store.state.vatCodeMap.get(item.fields.VATCode);
            if(VATCode && VATCode.Percent)
                return VATCode.Percent;
            return 0;
        },
        viewDetailItem:function (itemCode){
            let item =  this.$store.getters.getItemByCode(itemCode);
            if(!item) {
                this.detailItem = null;
                this.showDetail = false;
            }
            else {
                if (this.detailItem == null || this.detailItem.Code != item.Code) {
                    this.detailItem = item;
                    this.showDetail = true;
                }
            }
            //console.log('View Detail');
            //console.log(this.detailItem);
        }
    },
    watch:{
        '$route.params.orderid': function () {
            this.$store.dispatch('orderDetail',this.decode(this.$route.params.orderid));
        },
        '$.currentOrder.fields.Items.length': function (){
            this.updateSubTotals();
        }
    },
    async created(){
        let orderObj = await this.$store.dispatch('orderDetail',this.decode(this.$route.params.orderid));
        this.backup = Object.assign({},orderObj.order);
        if(Object.keys(this.backup).length === 0 && !this.$store.getters.getUserIsAdmin && !this.$store.getters.orderCanLiberate) {
            this.$store.dispatch('showNotificacion', {
                title: 'Acción no permitida',
                message: 'Está intentando ver / copiar un pedido que no fue creado por usted o su Grupo de Clientes',
                type: 'error'
            });
            this.$router.push('/latestshopping/all');
        }
    },
    template:`<div id="container" v-if="refreshFlag>0">
                <headerSectionComponent :items_in_car="$store.items_in_car" ></headerSectionComponent>
                <div class="section white">
                    <div class="container">
                      <template v-if="$store.getters.getAppState=='ready'">
                        <!--<registerComponent></registerComponent>-->
                        <loginComponent></loginComponent>
                        <div class="row">
                          <div class="left sticky-component items-category-tree">
                              <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                          </div>
                          <div class="col m9 s12 l9">
                            <div v-if="currentOrder">
                               <h4>
                                    <button class="btn" @click='goBack'>{{tr('Back')}}</button>
                                    <span class="center">{{tr('Sales Order')}} {{currentOrder.fields.SerNr}}</span> 
                               </h4>
                               <div class="row">
                                  <div class="col s8">
                                        <div class="col s12">
                                            <label>{{tr("Customer")}}:</label> <span> {{currentOrder.fields.CustName}}</span>
                                        </div>
                                        <div class="col s12">
                                            <label>{{tr("Delivery Address")}}:</label> <span> {{currentOrder.fields.DelAddress}}</span>
                                        </div>

                                    </div>
                                    <div class="col s4">
                                        <div class="col s12" v-if="currentOrder.fields.ContactName">
                                            <label>{{tr("Your Contact")}}:</label> <span> {{currentOrder.fields.ContactName}}</span>
                                        </div>
                                        <div class="col s12">
                                            <label>{{tr("TransDate")}}:</label> <span> {{currentOrder.fields.TransDate}}</span>
                                        </div>
                                        <div class="col s12">
                                            <label>{{tr("Expiration Date")}}:</label> <span> {{currentOrder.fields.DueDate}}</span>
                                        </div>
                                    </div>
                               </div>
                               <div class="col s12">
                                    <div class="row">
                                        <div class="col s12">
                                            <label>{{tr("Order Status")}}:</label> <span> {{currentOrder.fields.OrderStatus}}</span>
                                        </div>
                                        <button key="action-copy" title="Copiar" class="col s2 btn deep-orange accent-3" v-bind:class="buttonStyle" @click.stop="copyOrder()" v-if="canByCopy">
                                            <i class="fa fa-copy"></i> {{tr('Copy')}}
                                        </button>
                                        <template v-if="viewMode">
                                            <button key="action-edit" title="Editar" class="col s2  btn" v-bind:class="buttonStyle" @click.stop="changeMode('editOrder')" v-if="editable">
                                                <i class="fa fa-edit"></i> {{tr('Edit')}}
                                            </button>
                                        </template>
                                        <template v-else>
                                            <button key="action-edit-save" title="Save Order" class="col s2  btn" v-bind:class="buttonStyle" @click.stop="saveOrder" v-if="editable">
                                                <i class="fa fa-save"></i> {{tr('Save')}}
                                            </button>
                                            <button key="action-edit-cancel" title="Cancelar" class="col s2  btn" v-bind:class="buttonStyle" @click.stop="cancelEdit" v-if="editable">
                                                <i class="fa fa-ban"></i> {{tr('Cancel')}}
                                            </button>
                                        </template> 
                                        <button  key="action-liberate"  title="Confirmar" class="col s2 btn" v-bind:class="buttonStyleViewMode" @click.stop="orderAction('liberate', currentOrder)" v-if="liberator">
                                            <i class="fa fa-check"></i>{{tr('Approved')}}
                                        </button>
                                        <button key="action-cancel" title="Rechazar" class="col s2  btn" v-bind:class="buttonStyleViewMode" @click.stop="orderAction('reject', currentOrder)" v-if="liberator">
                                            <i class="fa fa-minus"></i>{{tr('Reject')}}
                                        </button>
                                    </div>
                               </div>
                               <div class="col s12">
                                   <template v-if="showDetail">
                                        <carModalDetailComponent :item="detailItem" :backToList="viewDetailItem"></carModalDetailComponent>
                                   </template>
                                    <table v-if="viewMode">
                                         <thead>
                                            <tr>
                                                <th>{{tr('Code')}}</th>
                                                <th width="35%">{{tr('Products')}}</th>
                                                <th>{{tr('Alternative Code')}}</th>
                                                <th>{{tr('UM')}}</th>
                                                <th>{{tr('Qty')}}</th>
                                                <th>{{tr('VAT Price')}}</th>
                                                <th>{{tr('Discount')}} %</th>
                                                <th>IVA %</th>
                                                <th>{{tr('Total Incl VAT')}}</th>
                                            </tr>
                                         </thead>
                                         <tbody>
                                            <tr v-for="item of currentOrder.fields.Items">
                                                <td>{{item.fields.ArtCode}}</td>
                                                <td width="35%" @click="viewDetailItem(item.fields.ArtCode)"><span class="blue-text text-darken-2">{{item.fields.Name}}</span></td>
                                                <td>{{item.fields.AlternativeCode}}</td>
                                                <td>{{item.fields.Unit}}</td>
                                                <td>{{item.fields.Qty}} </td>
                                                <td>$ {{formatFloat(item.fields.VATPrice)}}</td>
                                                <td>{{formatFloat(item.fields.Discount)}} %</td>
                                                <td>{{getVATCode(item)}} %</td>
                                                <td>$ {{formatFloat(item.fields.RowTotal)}}</td>
                                            </tr>
                                            <tr>
                                                <th  class="right-align">SubTotal:</th>
                                                <td  class="left-align" >{{formatFloat(currentOrder.fields.SubTotal)}}</td>
                                                <th  class="right-align">{{tr('Taxes')}}:</th>
                                                <td  class="left-align" >{{formatFloat(currentOrder.fields.VATTotal)}}</td>
                                                <th  class="right-align">Total</th>
                                                <td  class="left-align" >{{formatFloat(currentOrder.fields.Total)}}</td>
                                            </tr>
                                         </tbody>
                                    </table>
                                    <table v-if="!viewMode">
                                         <thead>
                                            <tr>
                                                <th>{{tr('Code')}}</th>
                                                <th width="35%">{{tr('Products')}}</th>
                                                <th>{{tr('UM')}}</th>
                                                <th>{{tr('Qty')}}</th>
                                                <th>{{tr('VAT Price')}}</th>
                                                <th>{{tr('Total Incl VAT')}}</th>
                                                <th>{{tr('Delete')}}</th>
                                            </tr>
                                         </thead>
                                         <tbody>
                                            <tr v-for="item of currentOrder.fields.Items">
                                                <td>{{item.fields.ArtCode}}</td>
                                                <td width="35%">{{item.fields.Name}}</td>
                                                <td>{{item.fields.Unit}}</td>
                                                <td class="row">
                                                    <span  class="col s2" @click="itemQtyIncrement(item)" style="margin-top: 1.0em;"><i class="fas fa-angle-up waves-effect" ></i></span>
                                                    <input class="col s8 center-align" type="text"  v-model="item.fields.Qty" placeholder="0" @keyup="updateSubTotals">
                                                    <span  class="col s2"@click="itemQtyDecrement(item)" style="margin-top: 1.0em;"><i class="fas fa-angle-down waves-effect" ></i></span>
                                                </td>
                                                <td>$ {{formatFloat(item.fields.VATPrice)}}</td>
                                                <td>$ {{formatFloat(item.fields.RowTotal)}}</td>
                                                <td><span @click="deleteItemInOrder(item)" ><i class="far fa-trash-alt" ></i></span></td>
                                            </tr>
                                            <tr>
                                                <th  class="right-align">SubTotal:</th>
                                                <td  class="left-align" >{{formatFloat(currentOrder.fields.SubTotal)}}</td>
                                                <th  class="right-align">{{tr('Taxes')}}:</th>
                                                <td  class="left-align" >{{formatFloat(currentOrder.fields.VATTotal)}}</td>
                                                <th  class="right-align">Total</th>
                                                <td  class="left-align" >{{formatFloat(currentOrder.fields.Total)}}</td>
                                            </tr>
                                            
                                         </tbody>
                                    </table>
                                    <div class="col s12">
                                        <span class="detalle" ><strong>Referencias de Empaque: </strong><br>
                                        EA: Unidad - PK: Pack - BX: Caja - RM: Resma - TB: Tubo - ST: Set - BL: Blister</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="container" >
                                    <div class="row" >
                                        <div class="col s8 offset-s2">
                                            <h3>
                                                <img src="../static/ecommerce/img/lg-spinner-gif.gif" >
                                                {{tr('Loading')}}
                                            </h3>
                                        </div>
                                        
                                   </div>
                                </div>
                            </div>
                          </div>
                          <div class="col m2 s12 l2 sticky-component right">
                            <userSectionComponent></userSectionComponent>
                            <carComponent></carComponent>
                          </div>
                        </div>
                      </template>
                    </div>
                </div>
            </div>`
});
